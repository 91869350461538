// Where the custom styles live...

html, body { 
    font-size: $base*.75;
    font-family: $font;
    line-height: 1.25em;

    @media (min-width: 768px) { 
        font-size: $base;
    }
}

header { 
    z-index: 100;
    position: fixed;
    top: 0; left: 0; right: 0;
    padding: .5rem 1rem;
    background: $navbg;
}

nav { 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-width: $full;
    margin: 0 auto;
}

.logo {
    display: block; 
    height: 3rem;
    width: 100%;
    margin-right: auto;
    &-wide { 
        background-size: contain;
        background-repeat: no-repeat;
    }
    &-text { 
        line-height: 3rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: $main;
    }
    &-img { display: none; }
}

.menukey { 
    display: block;
    text-align: center;
    color: $main;
    height: 3rem;
    text-decoration: underline;
    text-decoration-color: $main;
    &:hover {
        cursor: pointer; 
        color: $black;
    }
    > svg { 
        height:  3rem;
        width: 2rem;
        fill: $main;
    }
}



.bar { 
    position: absolute;
    pointer-events: none;
    opacity: 0;

    top: 4rem; left: 0; right: 0; bottom: 0;
    height: calc(100vh - 4rem);
    width: 100%;
    padding: 1.5rem;

    background: rgba( darken($main,15%), .85 );
    color: $white;
    text-align: center;

    transition: $trans;

    > .phone { 
        display: block;
        @media (min-width:720px) { 
            display: none;
        }
    }

    &.open { 
        opacity: 1;
        pointer-events: all;
    }

    @media (min-width:1023px) { 
        left: auto; right: 0; bottom: auto;
        height: auto;
        width: 400px;
    }

    @media (min-width:1200px) { 
        right: calc( 50% - 600px )
    }

}

.menu { 

    margin-bottom: 0;

    &-links { 
        display: block;
        max-width: 600px;
        margin: 0 auto;
        padding: .5rem;
        font-size: 1.25rem;

        color: #FFF;
        fill: #FFF;

        &:hover { 
            color: #FFF;
            text-decoration: underline;
        }
        &:active { 
            color: #FFF;
            opacity: .9;
        }
    
    @media (min-width:720px) { 
        font-size: 1.5em;
        padding: 1rem;
    }
    }

}

.social { 
    display: block;
    text-align: center;
    height: 3rem;
    width: 2rem;
    margin-right: .5rem;
    text-decoration: underline;
    text-decoration-color: $main;
    transition: $trans;
    &:hover {
        cursor: pointer; 
        transform: scale(1.025);
    }
    > svg { 
        width: 2rem;
        height: 100%;
    }
}

.nav-links { 
    display: none;

    min-width: 200px;
    margin: .5rem 1rem;
    padding: 0 .5rem;

    border: 1px solid $main;
    border-radius: .25rem;

    text-align: center;
    line-height: 2rem;

    color: $black;
    
    > .icon { fill: $main; }

    @media (min-width:$tab) { display: block; }

}

.close-menu { 
    display: block;
    margin-top: 2rem;
    padding: 1rem;
    opacity: .7;
    color: #FFF;
}

.anchor { 
    display: block;
    position: relative;
    top: -5rem;
    visibility: hidden;
}

main { 
    z-index: 50;
    padding-top: 3rem;
}

.container { 
    width: 100%;
    padding: 2.5rem 1.5rem;
}

.content {
    max-width: $full;
    margin: 0 auto;
}

.slider { 
    position: relative;
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
    height: calc( 60vh - 2rem );

    background-size: cover;
    background-position: center center;

    @media (min-width:$tab) { 
        height: calc( 100vh - 8rem );
        width: calc(100% - 3rem);
        margin: 1rem auto 0 auto;
    }

}

.slider-logos { 
    position: absolute;
    height: 5rem;
    bottom: -2.5rem;
    right: 1rem;
    @media (max-width:1400px) { 
        right: 50%;
        transform: translateX( 50% );
    }
}

.slider-tag { 
    position: absolute;
    bottom: 0; left: 0; right: 0;
    height: 20vh;
    background-image: linear-gradient(transparent 20%, rgba($black,.5) 100%);
}

.slider-tag > h1 { 
    position: absolute;
    bottom: 4rem; left: 1rem;
    width: 100%;
    padding: 0 10%;
    
    color: #FFF;
    font-size: 2rem;
    line-height: 1em;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 2px 5px $shadow;

    @media (min-width:1400px) { 
        width: 100%;
        padding: 0 1rem;
        text-align: left;
        bottom: 1rem;
    }
}

.title {
    margin: 1.5rem 0 2.25rem 0;
    h1 { 
        margin-bottom: 1.5rem;
        color: $title-colour;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: $main;
        text-decoration-thickness: 2px;
    }
}

.subtitle { 
    font-size: 1.25em;
    line-height: 1.5em;
    text-align: center;
}

.card { 
    padding: 1.5rem;
    background: #FFF;
    box-shadow: 1px 2px 5px $shadow;
    &-title { 
        font-weight: bold;
        font-size: 1.5rem;
        padding-bottom: .5rem;
        color: $card-title-colour;
    }
    &>p:last-child { 
        margin-bottom: 0;
    }
}

.brand { 
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.brand-logo {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    img { 
        width: 3rem;
        margin-right: 1.5rem;
        align-self: flex-start;
        @media (min-width:$tab) { 
            width: auto;
            height: 4.5rem;
        }
    } 
}

.brand-title { 
    margin: 0 auto 0 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: $main;
    color: var(--brand);
}

.brand-desc {
    width: 100%;
    flex-grow: 1;
    padding: 1.5rem;
    border: 1px solid var(--brand);
}

.product, .project {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.product-hero, .project-hero {
    position: relative;
    background-size: cover;
    background-position: center center;
    padding-top: 25vh;
}

.product-title {
    margin: 0;
    padding: 1rem 1.5rem; 
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
    color:$white;
    background: var(--brand);
    @media (max-width: 410px) { 
        padding: 1rem;
    }
}

.product-details { 
    flex: 1;
    padding: 1rem 0;
}

.project-details { 
    text-align: center;
}

.project-title { 
    padding: 1.5rem .5rem 1rem .5rem;
    font-size: 1.5rem;
    background: $tint;
}

.project-link { 
    position: absolute;
    bottom: -1rem; left: 50%;
    transform: translateX( -50% );
}

.contact-details { 
    text-align: center;
    > a { 
        display: block;
        padding: 1rem;
        color: currentColor;
        font-size: 1.25rem;
        &> span { 
            text-decoration: underline;
        }
    }
}

.gmap { 
    > iframe { 
        display: block;
    }
}

footer {
    padding: 2rem;
    background: darken($main,10%);
    color: $white;
}